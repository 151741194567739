import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em, rem, hideVisually, rgba } from "polished"
import { shuffle } from "lodash"
import {
  Meta,
  Infoblocks,
  Current,
} from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Styled from "../components/styled"
import SiteHero from "../components/site-hero"
import Map from "../components/map"
import Infobox from "../components/infobox"
import SitesCarousel from "../components/sites-carousel"
import { Label3, Label4 } from "../components/styled/label"
import { paragraphLargeStyles } from "../components/styled/paragraph"

const SiteTemplate = ({ data: { translations, site } }) => {
  const [nearbySites, setNearbySites] = useState(site.neighbours)

  useEffect(() => {
    setNearbySites(shuffle(site.neighbours))
  }, [])

  const mapQuery = `site_with_neighbours=${site.id}`
  // const mapQuery = [
  //   `site=${site.id}`,
  //   ...nearby.map((n) => `site=${n.id}`),
  // ].join(`&`)

  const NearbyComponent = () => {
    if (!nearbySites?.length) return null

    return (
      <Nearby>
        <Label3 as="h2">
          {site.theType == `biking`
            ? translations.global.alongTheWay
            : translations.global.nearby}
        </Label3>

        <SitesCarousel visibleSlides={2} data={nearbySites} />
      </Nearby>
    )
  }

  return (
    <Layout>
      <article>
        <SiteHero data={site} type={site.theTypeData} />

        <Columns>
          <ColumnMain>
            <div>
              {!!site.categories?.length && (
                <Categories>
                  <Label4
                    as="h2"
                    dangerouslySetInnerHTML={{
                      __html: translations.global.categories,
                    }}
                  />

                  <ul>
                    {site.categories.map((category) => (
                      <li key={category.id}>
                        <img
                          src={`https://assets.naturkartan.se/assets/categories/${category.id}/main.svg`}
                          alt=""
                          role="presentation"
                        />

                        <span
                          dangerouslySetInnerHTML={{ __html: category.name }}
                        />
                      </li>
                    ))}
                  </ul>
                </Categories>
              )}

              <CurrentStyled data={site} Title={Label3} Styled={Styled} />

              {!!site.description && (
                <Content
                  dangerouslySetInnerHTML={{ __html: site.description }}
                />
              )}

              <InfoblocksStyled data={site} Title={Label3} Styled={Styled} />

              <NearbyComponent />
            </div>
          </ColumnMain>

          <ColumnAside>
            <MapWrap>
              <h2
                dangerouslySetInnerHTML={{ __html: translations.global.map }}
              />

              <Map
                query={mapQuery}
                preselectedSiteId={site.id}
                menu="fullscreen"
                scrollZoom="false"
                styleControl="fullscreen"
                zoomControl="true"
                measureControl="fullscreen"
                locationControl="fullscreen"
                printControl="fullscreen"
                legendControl="fullscreen"
              />
            </MapWrap>

            <Infobox data={site} />
          </ColumnAside>
        </Columns>

        <NearbyMobile>
          <NearbyComponent />
        </NearbyMobile>
      </article>
    </Layout>
  )
}

export default SiteTemplate

export const Head = ({ data: { site } }) => {
  return (
    <Meta
      data={{
        title: site.name,
        socialImage: site.images[0]?.images.fallback.src,
        metaDescription: site.metaDescription,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        categories
        map
        nearby
        alongTheWay
      }
    }

    site: sitesJson(id: { eq: $id }) {
      id: nk_id
      theType: the_type
      theTypeData: the_type_data {
        name
        color
        slug
        title
      }
      path
      name
      description
      metaDescription: meta_description
      type
      facts
      directions
      address
      telephone
      email
      difficultyText: difficulty_text
      length
      time
      surface
      hills
      vegetation
      current
      parking
      communications
      regulations
      openingHours: opening_hours {
        open
        close
      }
      links {
        url
        text
        type
      }
      files {
        url
        text
      }
      categories {
        id: nk_id
        name
      }
      images {
        ...NKimageFragment
      }
      neighbours {
        ...SitesCardFragment
      }
    }
  }
`

const Nearby = styled.section`
  margin-top: ${rem(40)};
  padding-top: ${rem(40)};
  border-top: 1px solid;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    padding-top: ${rem(20)};
  }

  ${Label3} {
    color: ${({ theme }) => theme.colors.greenAlt};
    letter-spacing: 1px;
    margin-bottom: ${rem(20)};
  }
`

const Columns = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX()}

  margin-top: ${rem(50)};
  margin-bottom: ${rem(50)};
  letter-spacing: 1px;
  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: 0;
  }
`

const Categories = styled.div`
  width: 100%;
  padding-bottom: 2em;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 1.5em;
    left: 0;
    background-color: ${({ theme }) => rgba(theme.colors.gold, 0.2)};
  }

  ${Label4} {
    margin-bottom: 1.5em;
    ${({ theme }) => theme.fonts.set(`primary`, `normal`)};
  }

  ul {
    li {
      display: inline-block;
      margin-bottom: 1em;

      &:not(:last-child) {
        margin-right: 1.25em;
      }
    }

    img {
      width: 1.5em;
      margin-top: -0.15em;
      margin-right: 0.75em;
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 0.875em; /* 14 */
    }
  }
`

const ColumnMain = styled.div`
  ${({ theme }) => theme.grid.cell(8)}

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}
  }

  > div {
    padding-right: 10%;

    @media ${({ theme }) => theme.mq.mediumDown} {
      padding-right: 0;
    }
  }

  ${Nearby} {
    @media ${({ theme }) => theme.mq.mediumDown} {
      display: none;
    }
  }
`

const ColumnAside = styled.aside`
  ${({ theme }) => theme.grid.cell(4)}

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.cell(12)}

    margin-top: ${rem(30)};
  }
`

const Content = styled(Styled)`
  p:first-child {
    ${paragraphLargeStyles};
  }

  > *:not(:first-child) {
    max-width: ${rem(520)};
  }
`

const MapWrap = styled.section`
  height: ${rem(420)};
  margin-bottom: ${rem(10)};
  overflow: hidden;
  border-radius: ${rem(5)};

  > h2 {
    ${hideVisually()}
  }
`

const NearbyMobile = styled.footer`
  ${({ theme }) => theme.grid.container(`narrow`)}

  margin-bottom: ${rem(40)};
  display: none;

  @media ${({ theme }) => theme.mq.mediumDown} {
    display: block;
  }
`

const InfoblocksStyled = styled(Infoblocks)`
  margin-top: ${em(50)};
  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
  }
`

const CurrentStyled = styled(Current)`
  margin: ${em(30)} 0;
  @media ${({ theme }) => theme.mq.mediumDown} {
    margin: ${em(20)} 0;
  }
`
