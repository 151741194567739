import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import {
  StoreProvider as BrandSitesStoreProvider,
  CookiesConsent,
} from "@outdoormap/gatsby-plugin-brand-sites"
import "@outdoormap/frontend-utils/scss/reset.scss"

import Header from "../header"
import Footer from "../footer"
import { ParagraphSmall } from "../styled/paragraph"
import { StoreProvider } from "../../store"
import theme from "../../theme"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <BrandSitesStoreProvider language={process.env.GATSBY_LANG}>
        <StoreProvider>
          <React.Fragment>
            <InitialStyles />

            <Header />

            <main>{children}</main>

            <Footer />

            <CookiesConsent Text={ParagraphSmall} />
          </React.Fragment>
        </StoreProvider>
      </BrandSitesStoreProvider>
    </ThemeProvider>
  )
}

export default Layout

const InitialStyles = createGlobalStyle`
  @font-face {
    font-family: "Anton Regular";
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url("/fonts/anton-regular-webfont.woff2") format("woff2");
  }

  @font-face {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url("/fonts/archivo-regular-webfont.woff2") format("woff2");
  }

  @font-face {
    font-family: "Archivo";
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: url("/fonts/archivo-italic-webfont.woff2") format("woff2");
  }

  @font-face {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url("/fonts/archivo-bold-webfont.woff2") format("woff2");
  }

  @font-face {
    font-family: "Archivo";
    font-style: italic;
    font-weight: 700;
    font-display: fallback;
    src: url("/fonts/archivo-bolditalic-webfont.woff2") format("woff2");
  }

  // doesn't work on FF
  /* * {
    line-height: calc(2px + 2.3ex + 2px); // https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height
  } */

  *:focus:not(:focus-visible) {
    outline: none;
  }

  html {
    ${({ theme }) => theme.fonts.set(`primary`, `normal`)}

    font-size: 100%;  /* a11y */
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.offWhite};
  }

  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }

  strong {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)}
  }
`
