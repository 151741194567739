import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import SearchModal from "../search-modal"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"
import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"

const ENABLE_SEARCH = !!process.env.GATSBY_TYPESENSE_KEY

const Container = styled.nav`
  a,
  button {
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
`

const Sub = styled.ul`
  width: ${rem(220)};
  padding: 1.5em;
  display: none;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.greenAlt};
  z-index: 1;
  top: 100%;
  right: 0;
  text-align: right;
  box-shadow: 0 ${rem(4)} ${rem(10)} rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  animation: 0.2s ${({ theme }) => theme.easings.default};
  animation-name: ${({ theme }) => theme.animations.slideInY(-10)},
    ${({ theme }) => theme.animations.fadeIn};

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
    position: static;
    margin-top: 0.5em;
    padding: 0.75em;
    text-align: left;
    box-shadow: 0 ${rem(2)} ${rem(6)} rgba(0, 0, 0, 0.2);
  }

  li {
    align-items: center;
    font-size: ${rem(18)};
    font-weight: 700;
    text-align: left;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    line-height: ${rem(28)};
    ${({ theme }) => theme.fonts.set(`secondary`, `normal`)}

    &:not(:last-child) {
      margin-bottom: 0.5em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
`

const Base = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    display: block;
  }

  > li {
    position: relative;

    &:hover,
    &:focus,
    &:focus-within {
      ${Sub} {
        display: block;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`secondary`, `normal`)}

      color: ${({ theme }) => theme.colors.white};
      padding: 0.5em 1em;
      display: block;
      font-size: ${rem(18)};
      text-transform: uppercase;

      @media ${({ theme }) => theme.mq.largeDown} {
        padding: 0.2em 0;
      }

      svg {
        width: 0.7em;
        height: 0.7em;
        margin-left: 0.4em;
        display: inline-block;

        &.--search {
          width: 1em;
          height: 1em;
          position: relative;
          top: -0.05em;
        }
      }
    }
  }
`

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

const PrimaryNav = () => {
  const { pages, config, translations } = useStaticQuery(graphql`
    query {
      pages: allPagesJson {
        nodes {
          title
          url: path
        }
      }

      translations: translationsJson {
        search {
          title
          slug
        }
      }

      config: configJson {
        types {
          name
          slug
          color
          title
        }

        header {
          primaryNav: primary_nav {
            types
            dropdown {
              title
              items {
                title
                url
              }
            }
            items {
              title
              url
            }
          }
        }
      }
    }
  `)

  const [searchModalOpen, setSearchModalOpen] = useState(false)

  const searchModalRequestClose = () => {
    setSearchModalOpen(false)
  }

  const searchButtonClick = (e) => {
    e.preventDefault()
    setSearchModalOpen(true)
  }

  const items = [
    {
      title: config.header.primaryNav.types,
      sub: {
        type: `types`,
        data: config.types.map((t) => ({ url: `/${t.slug}`, ...t })),
      },
    },
    {
      title: config.header.primaryNav.dropdown.title,
      sub: {
        type: `more`,
        data: [...config.header.primaryNav.dropdown.items, ...pages.nodes],
      },
    },
    ...config.header.primaryNav.items.map(({ title, url }) => ({
      title,
      url,
    })),
  ]

  return (
    <Container>
      <Base>
        {items.map((base, i) => (
          <li key={i} data-has-sub={!!base.sub}>
            <ButtonLink to={base.url}>
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.sub && <ChevronDownSvg />}
            </ButtonLink>

            {base.sub && (
              <Sub>
                {base.sub.data
                  .filter((sub) => sub.title !== `Evenemang`)
                  .map((sub, i) => (
                    <li key={i}>
                      <Link key={i} to={sub.url}>
                        <span dangerouslySetInnerHTML={{ __html: sub.title }} />
                      </Link>
                    </li>
                  ))}
              </Sub>
            )}
          </li>
        ))}

        {ENABLE_SEARCH && (
          <li>
            <ButtonLink
              to={`/${translations.search.slug}`}
              onClick={searchButtonClick}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: translations.search.title,
                }}
              />

              <SvgMagnifyingGlass aria-hidden="true" className="--search" />
            </ButtonLink>

            <SearchModal
              isOpen={searchModalOpen}
              onRequestClose={searchModalRequestClose}
            />
          </li>
        )}
      </Base>
    </Container>
  )
}

export default PrimaryNav
