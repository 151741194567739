import React from "react"
import styled from "styled-components"

import SiteCard from "../site-card"

const getCellSize = ($perLine, decrement) => {
  if (!decrement) return 12 / $perLine
  let size = Math.floor(12 / ($perLine - decrement))
  size = size % 2 ? size - 1 : size
  return Math.max(size, 1)
}

const Container = styled.div`
  ${({ theme }) => theme.grid.container()}
  ${({ theme }) => theme.grid.grid()}
  ${({ theme }) => theme.grid.gridGutterX()}
  ${({ theme }) => theme.grid.gridGutterY(40)}
`

const Cell = styled.div`
  ${({ theme, $perLine }) => theme.grid.cell(getCellSize($perLine, 0))}

  @media ${({ theme }) => theme.mq.xlargeDown} {
    ${({ theme, $perLine }) => theme.grid.cell(getCellSize($perLine, 1))}
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme, $perLine }) => theme.grid.cell(getCellSize($perLine, 2))}
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    ${({ theme, $perLine }) => theme.grid.cell(getCellSize($perLine, 3))}
  }
`

const Grid = ({ data, type, ...props }) => {
  return (
    <Container {...props}>
      {data.map((site, i) => (
        <Cell key={i} $perLine={4}>
          <SiteCard data={site} hideTypeIcon={true} />
        </Cell>
      ))}
    </Container>
  )
}

export default Grid
