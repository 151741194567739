import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Meta, markdown } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import TypeHero from "../components/type-hero"
import SitesGrid from "../components/sites-grid"
import Map from "../components/map"
import Styled from "../components/styled"

// const collectCategories = (data) => {
//   const categories = {}
//   data.nodes.forEach(
//     (n) => n.mainCategory && (categories[n.mainCategory.id] = n.mainCategory)
//   )
//   return Object.values(categories)
// }

const TypeTemplate = ({ data: { config, sites, heroImg } }) => {
  const type = config.types[0]
  // const categories = useMemo(() => collectCategories(sites), [sites])

  const mapQuery = [
    `guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`,
    // ...categories.map((c) => `category=${c.id}`),
  ].join(`&`)

  return (
    <Layout>
      <TypeHero type={type} img={heroImg?.childImageSharp.gatsbyImageData} />

      {!!type.description && (
        <Description
          dangerouslySetInnerHTML={{
            __html: markdown(type.description),
          }}
        />
      )}

      <MapWrap>
        <Map
          query={mapQuery}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
        />
      </MapWrap>

      <SitesGridWrap>
        <SitesGrid data={sites.nodes} type={type} />
      </SitesGridWrap>
    </Layout>
  )
}

export default TypeTemplate

export const Head = ({ data: { config, heroImg } }) => {
  const type = config.types[0]

  return (
    <Meta
      data={{
        title: type.title,
        description: type.tagline,
        socialImage: heroImg?.childImageSharp.social.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($type: String!, $categories: [String]!) {
    config: configJson {
      types(name: $type) {
        name
        slug
        color
        title
        tagline
        description
      }
    }

    sites: allSitesJson(
      filter: { categories: { elemMatch: { nk_id: { in: $categories } } } }
      sort: { name: ASC }
    ) {
      nodes {
        ...SitesCardFragment

        categories {
          id: nk_id
        }
      }
    }

    heroImg: file(
      dir: { regex: "/src/assets/images/content$/i" }
      name: { eq: $type }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        social: gatsbyImageData(
          width: 1600
          height: 628
          sizes: null
          transformOptions: { fit: COVER, cropFocus: ATTENTION }
        )
      }
    }
  }
`

const SitesGridWrap = styled.div`
  margin-top: ${rem(100)};
  margin-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(30)};
    margin-bottom: ${rem(30)};
  }
`

const MapWrap = styled.div`
  ${({ theme }) => theme.grid.container()}

  width: 100%;
  height: 60vh;
  margin-top: ${rem(50)};
  margin-bottom: ${rem(50)};

  &:empty {
    display: none;
  }

  > * {
    border-radius: ${rem(10)};
  }

  @media ${({ theme }) => theme.mq.largeDown} {
    height: 90vh;
  }
`

const Description = styled(Styled)`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${rem(40)};
`
