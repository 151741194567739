import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"
import { ParagraphSmall } from "../styled/paragraph"

const Image = styled.figure`
  width: 100%;
  height: ${rem(350)};
  margin-bottom: 1em;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.gold};
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  > .-icon {
    position: absolute;
    z-index: 2;
    top: 0.5em;
    right: 0.5em;
    transform: scale(0.8);
    cursor: help;

    svg {
      border-radius: 10px;
      padding: 10%;
    }
  }
`

const Description = styled(ParagraphSmall)`
  margin-top: 0.3em;
`

const TextContainer = styled.div`
  min-height: ${rem(80)};
  padding: ${rem(5)} ${rem(20)} ${rem(15)} 0;
`

const Container = styled.article`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.offWhite};
  border-radius: 10px;

  ${Heading4} {
    padding-bottom: ${rem(10)};
  }

  a {
    h3 {
      font-weight: bold;
      line-height: ${rem(22)};
    }

    &:hover {
      ${Image} {
        opacity: 0.8;
      }

      ${Heading4} {
        color: ${({ theme }) => theme.colors.greenAlt};
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }
`

const SiteCard = ({
  data: { theType, theTypeData, name, path, image, excerpt },
  hideTypeIcon,
  ...props
}) => {
  return (
    <Container {...props}>
      <Link to={path}>
        <Image>{image && <GatsbyImage image={image} alt={name} />}</Image>

        <TextContainer>
          {name && (
            <Heading4 as="h3" dangerouslySetInnerHTML={{ __html: name }} />
          )}

          {excerpt && (
            <Description
              dangerouslySetInnerHTML={{
                __html: excerpt,
              }}
            />
          )}
        </TextContainer>
      </Link>
    </Container>
  )
}

export default SiteCard
