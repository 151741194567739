import { rem } from "polished"

const searchResult = {
  br: rem(15),
  backgroundColor: ({ theme }) => theme.colors.white,
  labelColor: ({ theme }) => theme.colors.white,
  labelBackgroundColor: ({ theme }) => theme.colors.gray,
}

export default searchResult
