import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem, darken } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

const Container = styled.section`
  padding: 0 ${rem(110)} ${rem(140)} ${rem(20)};
  align-self: flex-end;
  position: relative;
  z-index: 3;

  @media ${({ theme }) => theme.mq.largeDown} {
    ${({ theme }) => theme.grid.container(`xnarrow`)}

    padding: 0 0 ${rem(40)} 0;
    align-self: auto;
  }

  li {
    min-width: ${rem(200)};
  }
`

const LinkStyled = styled(Link)`
  ${({ theme }) => theme.fonts.set(`secondary`, `normal`)}

  width: 100%;
  height: 100%;
  padding: 0.2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(22)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.greenAlt};
  border-radius: ${rem(30)};
  transition: background-color 0.3s ${({ theme }) => theme.easings.default};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${rem(20)};
  }

  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.colors.yellowOpaque)};
    color: ${({ theme }) => theme.colors.greenAlt};
  }

  &:active {
    transition: none;
    background-color: ${({ theme, $color }) =>
      darken(0.2, theme.colors[$color || `metallic`])};
  }

  > .-icon {
    width: 2.3em;
    height: 2.3em;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};
    right: 7px;
    top: -2px;

    svg {
      padding: 15%;
    }
  }

  > span {
    white-space: nowrap;
  }
`

const TypeCtas = () => {
  const {
    config: { types },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        types {
          name
          slug
          color
          title
        }
      }
    }
  `)
  return (
    <Container>
      <ul>
        {types.map(({ name, slug, color, title }, i) => (
          <li key={i}>
            <LinkStyled to={`/${slug}`} $color={color}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </LinkStyled>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default TypeCtas
