import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import PhotoHero from "../components/photo-hero"

const MapWrap = styled.div`
  width: 100%;
  height: 90vh;
`

const MapPage = ({ data: { config, img } }) => {
  return (
    <Layout>
      <PhotoHero
        title={config.pages.map.title}
        intro={config.pages.map.intro}
        image={img}
      />

      <MapWrap>
        <Map
          query={`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="true"
          zoomControl="true"
          measureControl="true"
          locationControl="true"
          printControl="true"
          legendControl="true"
          legend="true"
        />
      </MapWrap>
    </Layout>
  )
}

export default MapPage

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.map.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      pages {
        map {
          title
          intro
        }
      }
    }

    img: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/discover.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`
