import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Meta, markdown } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Styled from "../components/styled"

const Container = styled.ul`
  ${({ theme }) => theme.grid.container()}

  min-height: ${rem(480)};
  padding-top: 30vh;
  padding-bottom: 30vh;
  display: flex;
  align-items: center;
  text-align: center;

  > * {
    width: 100%;
  }
`

const NotFoundPage = ({ data: { config } }) => {
  return (
    <Layout>
      <Container>
        <Styled>
          <h1 dangerouslySetInnerHTML={{ __html: config.page404.title }} />

          <div
            dangerouslySetInnerHTML={{
              __html: markdown(config.page404.description),
            }}
          />
        </Styled>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.page404.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      page404: page_404 {
        title
        description
      }
    }
  }
`
