import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import Icon from "../icon"
import { Heading3 } from "../styled/heading"
import { Label3 } from "../styled/label"
import { ReactComponent as SvgMapCompass } from "../../assets/images/icons/map-compass.svg"
import { ReactComponent as SvgMapFlag } from "../../assets/images/icons/map-flag.svg"
import { ReactComponent as SvgMapLocation } from "../../assets/images/icons/map-location.svg"

const iconsMapping = {
  mapLocation: <SvgMapLocation />,
  mapFlag: <SvgMapFlag />,
  mapCompass: <SvgMapCompass />,
}

const Container = styled.section`
  margin-top: ${rem(30)};
  padding: ${rem(50)} 0 ${rem(80)} 0;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.yellowAlt};

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Heading = styled(Label3)`
  color: ${({ theme }) => theme.colors.greenAlt};
`

const Links = styled.ul`
  margin-top: ${rem(20)};
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-radius: ${rem(8)};
  border: 1px solid ${({ theme }) => theme.colors.black};

  li {
    min-width: 25%;
    margin-top: -1px;
    margin-left: -1px;
    flex: 1;
    border: solid ${({ theme }) => theme.colors.black};
    border-width: 1px 0 0 1px;

    @media ${({ theme }) => theme.mq.largeDown} {
      min-width: 50%;
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      min-width: 100%;
    }
  }

  a {
    height: 100%;
    padding: ${rem(50)} ${rem(20)};
    display: block;
    transition: background-color 0.3s ${({ theme }) => theme.easings.default};

    @media ${({ theme }) => theme.mq.largeDown} {
      padding: ${rem(20)};
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);

      > .-icon {
        transform: scale(1.15);
      }
    }

    &:active {
      transition: none;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .-icon {
    width: ${rem(70)};
    height: ${rem(70)};
    margin: 0 auto;
    margin-bottom: ${rem(15)};
    display: block;
    transition: transform 0.3s ${({ theme }) => theme.easings.default};
    border-radius: 100%;
    border: 1px solid ${({ theme }) => theme.colors.black};

    @media ${({ theme }) => theme.mq.largeDown} {
      width: ${rem(50)};
      height: ${rem(50)};
    }
  }
`

const SvgIcon = ({ name }) => iconsMapping[name]

const GoodToKnow = () => {
  const {
    config: {
      footer: { goodToKnow },
    },
  } = useStaticQuery(graphql`
    query {
      config: configJson {
        footer {
          goodToKnow: good_to_know {
            title
            items {
              url
              title
              icon
            }
          }
        }
      }
    }
  `)

  if (!goodToKnow.items.length) return null

  return (
    <Container>
      <div>
        <Heading
          as="h2"
          dangerouslySetInnerHTML={{ __html: goodToKnow.title }}
        />

        <Links>
          {goodToKnow.items.map((item, i) => (
            <li key={i}>
              <Link to={item.url}>
                <Icon
                  iconSvg={<SvgIcon name={item.icon} />}
                  className="-icon"
                  aria-hidden="true"
                />

                <Heading3 dangerouslySetInnerHTML={{ __html: item.title }} />
              </Link>
            </li>
          ))}
        </Links>
      </div>
    </Container>
  )
}

export default GoodToKnow
