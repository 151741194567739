import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { hideVisually, rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Map from "../components/map"
import HomeMediaBlock from "../components/home-media-block"
import IndexSites from "../components/index-sites"
import PostsHighlights from "../components/posts-highlights"
import GoodToKnow from "../components/footer/good-to-know"
import { ReactComponent as SvgBulb } from "../assets/images/icons/bulb.svg"

const MapWrap = styled.section`
  height: 80vh;
  min-height: ${rem(220)};
  z-index: 10;
  position: relative;

  @media ${({ theme }) => theme.mq.largeDown} {
    margin-top: ${rem(40)};
    height: 70vh;
  }

  > h2 {
    ${hideVisually()}
  }

  > .gatsby-image-wrapper {
    width: ${rem(200)};
    height: ${rem(200)};
    position: absolute !important;
    z-index: 1;
    top: ${rem(-30)};
    right: ${rem(100)};
    pointer-events: none;
    filter: drop-shadow(0 ${rem(10)} ${rem(15)} rgba(0, 0, 0, 0.7));

    @media ${({ theme }) => theme.mq.largeDown} {
      width: ${rem(140)};
      height: ${rem(140)};
      right: ${rem(80)};
      filter: drop-shadow(0 ${rem(5)} ${rem(10)} rgba(0, 0, 0, 0.5));
    }

    @media ${({ theme }) => theme.mq.mediumDown} {
      display: none;
    }
  }

  margin-top: 0;
`

const Sites = styled.div`
  background-color: ${({ theme }) => theme.colors.offWhite};
  padding-top: ${rem(60)};

  @media ${({ theme }) => theme.mq.xlargeDown} {
    padding-top: ${rem(10)};
  }

  > .-hr {
    ${({ theme }) => theme.grid.container()}

    div {
      height: 0;
      margin-bottom: ${rem(40)};
      display: block;
      overflow: hidden;
    }
  }
`

const IndexPage = ({
  data: {
    translations,
    config,
    pageAbout,
    latestArticles,
    latestNews,
    hiking,
    imgHome,
    imgMiniMap,
  },
}) => {
  const sites = []

  const findType = (name) => config.types.find((t) => t.name === name)

  if (hiking.nodes.length) {
    sites.push({ type: findType(`hiking`), items: hiking.nodes })
  }

  const mapQuery = [
    `guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`,
    ...[33 /* , 31, 16, 8, 37, 38, 32, 96, 7 */].map((c) => `category=${c}`), // hiking,biking,mountainbiking,downhill,canoeing,trailrunning,running,pilgrim,walking
  ].join(`&`)

  return (
    <Layout>
      <HomeMediaBlock
        headingLevel={1}
        data={{
          image: imgHome,
          title: config.pages.index.hero.title,
          description: config.pages.index.hero.description,
          cta: {
            title: config.pages.index.hero.cta,
            url: pageAbout.path,
          },
        }}
      />

      <MapWrap>
        <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

        <GatsbyImage
          image={imgMiniMap.childImageSharp.gatsbyImageData}
          objectFit="contain"
          alt={translations.global.map}
        />

        <Map
          query={mapQuery}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
        />
      </MapWrap>

      <Sites>
        {sites.map(({ type, items }, i) => (
          <IndexSites key={i} type={type} sites={items} />
        ))}
      </Sites>

      <PostsHighlights
        data={latestArticles.nodes}
        heading={translations.global.latestArticles}
        moreText={translations.global.seeAll}
        moreLink={`/${config.pages.articles.slug}`}
        icon={<SvgBulb />}
      />

      <PostsHighlights
        data={latestNews.nodes}
        heading={translations.global.latestNews}
        moreText={translations.global.seeAll}
        moreLink={`/${config.pages.news.slug}`}
        icon={<SvgBulb />}
      />

      <GoodToKnow />
    </Layout>
  )
}

export default IndexPage

export const Head = ({ data: { config } }) => {
  return (
    <Meta
      data={{
        title: config.pages.index.title,
        titleOverridePattern: true,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        map
        seeAll
        latestArticles
        latestNews
      }
    }

    config: configJson {
      pages {
        index {
          title
          hero {
            title
            description
          }
        }

        articles {
          slug
        }

        news {
          slug
        }
      }

      types {
        name
        slug
        color
        title
        tagline
        description
      }
    }

    pageAbout: pagesJson(nk_id: { eq: "18131" }) {
      path
    }

    hiking: allSitesJson(
      filter: { the_type: { eq: "hiking" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    latestNews: allNewsJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...NewsCardFragment
      }
    }

    latestArticles: allArticlesJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    imgMiniMap: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "mini-map.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 768, placeholder: NONE)
      }
    }

    imgHome: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/hiking.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`
