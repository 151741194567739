import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import EventCard from "../components/event-card"
import PhotoHero from "../components/photo-hero"

const Description = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${rem(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
  }

  p {
    font-size: ${rem(18)};
    line-height: ${rem(24)};
    color: ${({ theme }) => theme.colors.gray};
    margin-top: ${rem(20)};
  }
`

const Grid = styled.ul`
  ${({ theme }) => theme.grid.container()}

  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${rem(20)};
    margin-bottom: ${rem(20)};
  }

  > ul {
    ${({ theme }) => theme.grid.grid()}
    ${({ theme }) => theme.grid.gridGutterX()}
    ${({ theme }) => theme.grid.gridGutterY(30)}

    > li {
      ${({ theme }) => theme.grid.cell(4)}

      @media ${({ theme }) => theme.mq.xlargeDown} {
        ${({ theme }) => theme.grid.cell(6)}
      }

      @media ${({ theme }) => theme.mq.smallDown} {
        ${({ theme }) => theme.grid.cell(12)}
      }
    }
  }
`

const EventsPage = ({ data: { config, events, img } }) => {
  return (
    <Layout>
      <PhotoHero
        title={config.pages.events.title}
        intro={config.pages.events.intro}
        image={img}
      />

      {config.pages.events.description && (
        <Description
          dangerouslySetInnerHTML={{
            __html: config.pages.events.description,
          }}
        />
      )}

      {!!events.nodes.length && (
        <Grid>
          <ul>
            {events.nodes.map((item, i) => (
              <li key={i}>
                <EventCard data={item} />
              </li>
            ))}
          </ul>
        </Grid>
      )}
    </Layout>
  )
}

export default EventsPage

export const Head = ({ data: { config } }) => {
  return <Meta data={{ title: config.pages.events.title }} />
}

export const pageQuery = graphql`
  query {
    config: configJson {
      pages {
        events {
          title
          intro
          description
        }
      }
    }

    events: allEventsJson(sort: { occurs_at: DESC }) {
      nodes {
        ...EventsCardFragment
      }
    }

    img: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/discover.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }
  }
`
