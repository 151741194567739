import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading1 } from "../styled/heading"

const Container = styled.header`
  height: 50vh;
  min-height: ${rem(280)};
  max-height: ${rem(540)};
  position: relative;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.darkGreenAlt};
`

const Text = styled.div`
  ${({ theme }) => theme.grid.container()}

  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  color: ${({ theme }) => theme.colors.white};
`

const Title = styled.h1`
  ${({ theme }) => theme.fonts.set(`secondary`, `normal`)};

  margin-bottom: ${rem(20)};
  padding: ${rem(5)} ${rem(25)} ${rem(7)} ${rem(25)};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  font-size: ${rem(18)};
  display: flex;
  align-items: center;
  text-transform: uppercase;
`

const Intro = styled(Heading1)`
  margin-bottom: ${rem(40)};
  font-weight: bold;
  text-align: left;
  line-height: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    line-height: ${rem(30)};
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    );
  }

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const PhotoHero = ({ image, title, intro, switchHeadingTags }) => {
  if (!image && !title && !intro) return null

  return (
    <Container>
      {image && (
        <Image>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={title}
          />
        </Image>
      )}

      {(title || intro) && (
        <Text>
          {title && (
            <Title
              dangerouslySetInnerHTML={{ __html: title }}
              as={switchHeadingTags && `p`}
            />
          )}

          {intro && (
            <Intro
              as={switchHeadingTags ? `h1` : `p`}
              dangerouslySetInnerHTML={{
                __html: intro,
              }}
            />
          )}
        </Text>
      )}
    </Container>
  )
}

export default PhotoHero
