import React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  width: 3em;
  height: 3em;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    &:first-child {
      z-index: 1;

      ${({ $withDropShadow }) =>
        $withDropShadow &&
        css`
          filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
        `}

      ${({ $outline }) =>
        $outline &&
        css`
          fill: none;
          stroke: currentColor;
          stroke-width: 5px;
          filter: none;

          path {
            transform: scale(0.99);
            transform-origin: center center;
          }
        `}
    }

    &:last-child {
      padding: 27.5%;
      z-index: 2;
      color: ${({ theme, $iconColor }) => theme.colors[$iconColor]};
      background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    }
  }
`

const Icon = ({
  iconSvg,
  iconColor,
  withDropShadow,
  bgColor,
  outline,
  ...props
}) => {
  if (!iconSvg) return null

  return (
    <Container
      $withDropShadow={withDropShadow}
      $bgColor={bgColor}
      $outline={outline}
      $iconColor={iconColor}
      {...props}
    >
      {iconSvg}
    </Container>
  )
}

export default Icon
