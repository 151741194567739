import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import SitesCarousel from "../sites-carousel"
import { Heading2 } from "../styled/heading"
import { Paragraph } from "../styled/paragraph"
import { anchorStyles } from "../styled/anchor"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Header = styled.header`
  display: grid;
  grid-template-areas:
    "a"
    "b";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  margin-bottom: ${rem(15)};
  padding-top: 0;
  padding-right: ${rem(50)};
  position: relative;
  align-items: center;

  h2 {
    ${({ theme }) => theme.fonts.set(`secondary`, `normal`)}
    line-height: ${rem(30)};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.greenAlt};
  }
`
const Cta = styled.footer`
  margin-top: ${rem(10)};

  a {
    ${({ theme }) => theme.fonts.set(`secondary`, `normal`)}
    ${anchorStyles}

    background-color: ${({ theme }) => theme.colors.greenAlt};
    border-radius: 30px;
    padding: ${rem(13)} ${rem(18)};
    color: ${({ theme }) => theme.colors.white};
    z-index: 10;
    position: relative;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.yellowOpaque};

      svg {
        transform: translateX(0.3em);
      }
    }

    span {
      margin-right: 0.4em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      position: relative;
      bottom: 2px;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  width: 100%;
  padding-bottom: ${rem(20)};
  padding-top: ${rem(50)};
`

const IndexSites = ({ type, sites }) => {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          seeAll
        }
      }
    }
  `)

  if (!sites || !sites.length) return null

  return (
    <Container>
      <Header>
        {type.title && (
          <Heading2 dangerouslySetInnerHTML={{ __html: type.title }} />
        )}

        {type.tagline && (
          <Paragraph dangerouslySetInnerHTML={{ __html: type.tagline }} />
        )}
      </Header>

      <SitesCarousel
        data={sites}
        type={type.name}
        visibleSlides={3}
        hideTypeIcons={true}
      />

      <Cta>
        <Link to={`/${type.slug}`}>
          <span
            dangerouslySetInnerHTML={{ __html: translations.global.seeAll }}
          />

          <SvgArrowRight aria-hidden="true" />
        </Link>
      </Cta>
    </Container>
  )
}

export default IndexSites
