import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { rem } from "polished"

import TypeCtas from "../type-ctas"

const Container = styled.section`
  position: relative;

  > div {
    min-height: 100vh;
    padding-top: ${rem(100)};
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.brown};

    @media ${({ theme }) => theme.mq.xsmallDown} {
      /* min-height: ${rem(440)};
      max-height: ${rem(550)}; */
      border-radius: 0;
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.35)
      );

      @media ${({ theme }) => theme.mq.largeDown} {
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.45),
          rgba(0, 0, 0, 0.45),
          rgba(0, 0, 0, 0.45),
          rgba(0, 0, 0, 0.45)
        );
      }
    }
  }
`

const Inner = styled.div`
  display: flex;

  @media ${({ theme }) => theme.mq.largeDown} {
    flex-direction: column;
    justify-content: flex-end;
  }
`

const Media = styled.div`
  > video,
  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: 1;
    top: 0;
    left: 0;
  }

  > video {
    height: 100%;
    width: 130vh;
    min-width: 100%;
    min-height: 56vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Text = styled.div`
  ${({ theme }) => theme.grid.container()}

  z-index: 10;
  align-self: flex-end;
  max-width: ${rem(700)};

  div {
    margin: 0 ${rem(15)} ${rem(160)} ${rem(115)};

    @media ${({ theme }) => theme.mq.largeDown} {
      margin: 0 0 ${rem(40)} 0;
    }
  }

  h1 {
    ${({ theme }) => theme.fonts.set(`secondary`, `normal`)}

    color: ${({ theme }) => theme.colors.yellowOpaque};
    font-size: ${rem(36)};
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: ${rem(16)};

    @media ${({ theme }) => theme.mq.largeDown} {
      font-size: ${rem(30)};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.offWhite};
    font-size: ${rem(24)};
    line-height: 1.3;

    @media ${({ theme }) => theme.mq.largeDown} {
      font-size: ${rem(20)};
    }
  }
`

const HomeMediaBlock = ({ data, headingLevel = 2, ...props }) => {
  if (!data) return null

  return (
    <Container {...props}>
      <Inner>
        <Media>
          {data.video && (
            <video
              src={data.video}
              preload="auto"
              playsInline
              autoPlay
              loop
              muted
            />
          )}

          {data.image && (
            <GatsbyImage
              image={data.image.childImageSharp.gatsbyImageData}
              alt={data.title}
            />
          )}
        </Media>

        <Text>
          <div>
            <h1 dangerouslySetInnerHTML={{ __html: data.title }} />

            <p dangerouslySetInnerHTML={{ __html: data.description }} />
          </div>
        </Text>

        <TypeCtas />
      </Inner>
    </Container>
  )
}

export default HomeMediaBlock
