import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { ImagesCarousel } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading1 } from "../styled/heading"

const Container = styled.header``

const Header = styled.header`
  height: 70vh;
  min-height: ${rem(320)};
  max-height: ${rem(640)};
  margin-bottom: ${rem(50)};
  position: relative;
  text-align: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(20)};
  }
`

const HeaderText = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  div {
    ${({ theme }) => theme.grid.container()}
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.white};
  }
`

const Title = styled(Heading1)`
  margin-bottom: ${rem(40)};
  font-weight: bold;
  text-align: left;
  line-height: ${rem(40)};
  z-index: 10;

  @media ${({ theme }) => theme.mq.mediumDown} {
    line-height: ${rem(30)};
  }
`

const Label = styled.label`
  ${({ theme }) => theme.fonts.set(`secondary`, `normal`)};

  margin-bottom: ${rem(20)};
  padding: ${rem(5)} ${rem(25)} ${rem(3)} ${rem(25)};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  font-size: ${rem(18)};
  display: flex;
  z-index: 10;
  align-items: center;
  text-transform: uppercase;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(10)};
  }
`

const Images = styled.div`
  width: 100%;
  height: 70vh;
  max-height: ${rem(640)};
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    );
  }
`

const SiteHero = ({ data, type }) => {
  return (
    <Container>
      <Header>
        <Images>
          <ImagesCarousel
            data={data.images}
            title={data.name}
            fullscreenReady={false}
          />
        </Images>

        <HeaderText>
          <div>
            <Label dangerouslySetInnerHTML={{ __html: type.title }} />

            {data.name && (
              <Title dangerouslySetInnerHTML={{ __html: data.name }} />
            )}
          </div>
        </HeaderText>
      </Header>
    </Container>
  )
}

export default SiteHero
