import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading1 } from "../styled/heading"
import { Paragraph } from "../styled/paragraph"

const TypeHero = ({
  type: { name, color, title, tagline, description },
  img,
  ...rest
}) => {
  return (
    <Container {...rest}>
      {img && <GatsbyImage image={img} alt={title} />}

      <Inner>
        <Info>
          {tagline && (
            <Title>
              <Label dangerouslySetInnerHTML={{ __html: title }} />

              {tagline && (
                <Heading1
                  as="h2"
                  dangerouslySetInnerHTML={{ __html: tagline }}
                />
              )}
            </Title>
          )}
        </Info>
      </Inner>
    </Container>
  )
}

export default TypeHero

const Container = styled.section`
  height: 50vh;
  min-height: ${rem(320)};
  max-height: ${rem(440)};
  position: relative;

  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: 1;
    top: 0;
    left: 0;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
      );
    }
  }
`

const Inner = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.greenAlt};
  z-index: 10;
`

const Info = styled.div`
  ${({ theme }) => theme.grid.container()}
  color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  align-self: flex-end;

  @media ${({ theme }) => theme.mq.largeDown} {
    width: 100%;
  }

  ${Heading1} {
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  ${Paragraph} {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${rem(36)};
  }
`

const Title = styled.div`
  padding: ${rem(50)} ${rem(70)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${rem(20)};
  }
`

const Label = styled.label`
  ${({ theme }) => theme.fonts.set(`secondary`, `normal`)};
  margin-bottom: ${rem(20)};
  padding: ${rem(5)} ${rem(25)} ${rem(3)} ${rem(25)};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  font-size: ${rem(18)};
  max-width: ${rem(115)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`
