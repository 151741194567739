import { rgba } from "polished"

let colors = {
  white: `#fff`,
  black: `#29292b`,
  metallic: `#43484c`,
  cream: `#efece8`,
  creamLight: `#f4f2ef`,
  gold: `#896c4c`,
  yellow: `#ffcd00`,
  yellowAlt: rgba(250, 230, 170, 0.5),
  yellowOpaque: rgba(250, 230, 170, 1),
  brown: `#bb8b6f`,
  blue: `#005096`,
  gray: `#5a5a5a`,
  green: `#41885c`,
  greenAlt: `#006e6e`,
  darkGreenAlt: `#003c3c`,
  offWhite: `#fafaf0`,
  red: `#884141`,
}

colors = {
  ...colors,
  // by function:
  imageBackground: colors.greenAlt,
}

export default colors
